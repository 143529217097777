import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import xor from 'lodash/xor';
import { ThunkAction } from 'redux-thunk';

import { ECampsiteType, TAmenitySlug } from '@/constants/amenitiesFilter';
import { TCancelPoliciesFilters, TCheckInTypePolicyFilters } from '@/constants/moreFilter';
import { ESearchFilters } from '@/constants/searchFilters';
import { TRootState } from '@/redux/rootReducer';
import { trackRenterSearchFilterUpdatedEvent } from '@/services/analytics/search';
import { ERentalType } from '@/services/analytics/types';
import { trackEvent } from '@/services/track-event';
import {
  TStayCategories,
  TStayPropertyType,
  TStayType,
  TVehicleType,
} from '@/services/types/search/rentals/id';
import { IAction } from '@/utility/redux/action';

import { getSearchMetaResults } from './search';

const SET_FORM_FILTER = 'searchForm/SET_FORM_FILTER';
const RESET_FORM = 'searchForm/RESET_FORM';

export interface ISearchFormFilters {
  [ESearchFilters.ADDRESS]: string;
  [ESearchFilters.BOUNDS_NE]: string;
  [ESearchFilters.BOUNDS_SW]: string;
  [ESearchFilters.NEAR]: string;
  [ESearchFilters.PAGE_LIMIT]: string;
  [ESearchFilters.PAGE_OFFSET]: string;
  [ESearchFilters.FILTER_TYPE]: TVehicleType[] | TStayType[];
  [ESearchFilters.FILTER_PROPERTY]: TStayPropertyType[];
  [ESearchFilters.FILTER_VEHICLE_MAKE]: string;
  [ESearchFilters.FILTER_VEHICLE_MAKE_MODELS]: string;
  [ESearchFilters.PRICE_MIN]: number;
  [ESearchFilters.PRICE_MAX]: number;
  [ESearchFilters.SEATBELTS]: number;
  [ESearchFilters.SLEEPS]: number;
  [ESearchFilters.BATHROOMS]: number;
  [ESearchFilters.BEDROOMS]: number;
  [ESearchFilters.BEDS]: number;
  [ESearchFilters.FILTER_FEATURE]: TAmenitySlug[];
  [ESearchFilters.CAMPGROUND_FEATURES]: TAmenitySlug[];
  [ESearchFilters.CAMPSITE_RV_SITE_FEATURES]: TAmenitySlug[];
  [ESearchFilters.CAMPSITE_TENT_SITE_FEATURES]: TAmenitySlug[];
  [ESearchFilters.CAMPSITE_LODGING_FEATURES]: TAmenitySlug[];
  [ESearchFilters.CAMPSITE_STATIONARY_CAMPER_SITE_FEATURES]: TAmenitySlug[];
  [ESearchFilters.CAMPSITE_CATEGORY_TYPES]: ECampsiteType[];
  [ESearchFilters.CAMPSITE_SUPPORTED_SUBTYPES]: TStayCategories[] | TVehicleType[];
  [ESearchFilters.CAMPSITE_MAX_VEHICLE_LENGTH]: number;
  [ESearchFilters.CAMPSITE_BATHROOMS]: number;
  [ESearchFilters.CAMPSITE_BEDROOMS]: number;
  [ESearchFilters.CAMPSITE_BEDS]: number;
  [ESearchFilters.FILTER_KEYWORDS]: string;
  [ESearchFilters.INSTANT_BOOK]: boolean;
  [ESearchFilters.LENGTH_BETWEEN]: string[];
  [ESearchFilters.SCORE]: number;
  [ESearchFilters.CANCEL_POLICY]: TCancelPoliciesFilters[];
  [ESearchFilters.STAY_CHECK_IN_TYPE]: TCheckInTypePolicyFilters[];
  [ESearchFilters.STAY_PARKING_ONSITE]: boolean;
  [ESearchFilters.UNLIMITED_GENERATOR]: boolean;
  [ESearchFilters.UNLIMITED_MILES]: boolean;
  [ESearchFilters.PRICE_MIN]: number;
  [ESearchFilters.DELIVERY]: boolean;
  [ESearchFilters.DELIVERY_ADDRESS]: string;
  [ESearchFilters.DELIVERY_STATIONARY]: string;
  [ESearchFilters.DELIVERY_RADIUS]: string;
  [ESearchFilters.WEIGHT_GREATER]: number;
  [ESearchFilters.WEIGHT_LESSER]: number;
  [ESearchFilters.SUGGESTED_TOWING_CAPACITY]: number;
  [ESearchFilters.DELIVERY_QUERY]: string;
  [ESearchFilters.DELIVERY_LOCATION_ID]: string;
  [ESearchFilters.DELIVERY_CENTER]: string;
  [ESearchFilters.DELIVERY_DETAILS]: string;
  [ESearchFilters.TOWING_ID]: number;
  [ESearchFilters.YEAR_GREATER]: number;
  [ESearchFilters.YEAR_LESSER]: number;
  [ESearchFilters.OMIT_AGGREGATION]: boolean;
  [ESearchFilters.INCLUDE_TOTAL_PRICE_AGGREGATION]: string;
  [ESearchFilters.AVAILABILITY_WINDOW]: boolean;
  [ESearchFilters.FILTER_TAGS]: string;
  [ESearchFilters.FILTER_TAGS_SLUG]: string;
  [ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID]: number;
  [ESearchFilters.GEOIP_ADDRESS]: string;
  [ESearchFilters.DELIVERY_CAMPGROUND]: string;
}
export enum EFilterMethod {
  SLIDER = 'slider',
  INPUT = 'input',
  CHECKBOX = 'checkbox',
  SWITCH = 'switch',
  RADIO = 'radio',
  DROPDOWN = 'dropdown',
  MAP_PAN = 'map_pan',
  MAP_ZOOM_IN = 'map_zoom_in',
  MAP_ZOOM_OUT = 'map_zoom_out',
  ODSTAYS_DELIVERY_SEARCH_AD = 'odstays_delivery_search_ad',
}

export const PERSIST_FORM_FILTERS = [
  ESearchFilters.BOUNDS_NE,
  ESearchFilters.BOUNDS_SW,
  ESearchFilters.NEAR,
  ESearchFilters.PAGE_LIMIT,
  ESearchFilters.PAGE_OFFSET,
];

type ISearchFormFiltersPayload = {
  filters: Partial<ISearchFormFilters>;
  filterMethod: EFilterMethod | null;
  ignorePersistedFilters?: boolean;
  ignoredFiltersUpdate?: (keyof ISearchFormFilters)[];
  isOutdoorsyStay?: boolean;
};

type TEventFilters = Partial<ISearchFormFilters & { superhost: string }>;

interface ISetFormFilterAction extends IAction {
  type: typeof SET_FORM_FILTER;
  payload: ISearchFormFiltersPayload;
}

interface IResetFormAction extends IAction {
  type: typeof RESET_FORM;
}

type TAction = ISetFormFilterAction | IResetFormAction;

type TSetFormFilterFunction = (
  payload: ISearchFormFiltersPayload,
  ignoreSearchMeta?: boolean,
) => ThunkAction<void, TRootState, void, TAction>;

type FilterFeaturesKeys =
  | ESearchFilters.FILTER_FEATURE
  | ESearchFilters.CAMPSITE_RV_SITE_FEATURES
  | ESearchFilters.CAMPSITE_TENT_SITE_FEATURES
  | ESearchFilters.CAMPSITE_LODGING_FEATURES
  | ESearchFilters.CAMPGROUND_FEATURES;

type TFilterFeatures = Partial<Record<FilterFeaturesKeys, TAmenitySlug[] | undefined>>;

type TClearFormFilterFunction = (payload: {
  filters: ESearchFilters[];
  filtersToRestore?: ESearchFilters[];
  filterFeatures?: TFilterFeatures;
  ignorePersitedFilters?: boolean;
  isOutdoorsyStay?: boolean;
}) => ThunkAction<void, TRootState, void, TAction>;

const getFilterTags = (value: string | undefined) =>
  value ? value.split(',').map(tag => tag.trim()) : [];

const isSuperhostFilterChanged = (
  before: TEventFilters,
  after: TEventFilters,
): 'before' | 'after' | 'no-change' => {
  // The superhost filter is includes on filter[tags-slug]=superhost
  // We need to clean it up so that it can be used in the event tracking
  // The superhost filter is a checkbox filter, so we need to remove it from the filters object
  // and add it to the superhost property
  const tagsSlugBefore = before[ESearchFilters.FILTER_TAGS_SLUG];
  const tagsSlugAfter = after[ESearchFilters.FILTER_TAGS_SLUG];

  // That means the superhost filter is not changed
  if (typeof tagsSlugBefore !== 'string' && typeof tagsSlugAfter !== 'string') return 'no-change';

  const filterTagsBefore = getFilterTags(tagsSlugBefore);
  const filterTagsAfter = getFilterTags(tagsSlugAfter);
  const newFilterTagsBefore = xor(filterTagsBefore, ['superhost']);
  const newFilterTagsAfter = xor(filterTagsAfter, ['superhost']);

  if (newFilterTagsBefore.length === newFilterTagsAfter.length) return 'no-change';
  else if (newFilterTagsBefore.length <= newFilterTagsAfter.length) return 'before';
  return 'after';
};

const removeSuperhostFromFilterTags = (filters: TEventFilters) => {
  if (typeof filters[ESearchFilters.FILTER_TAGS_SLUG] === 'undefined') return filters;

  const tags = getFilterTags(filters[ESearchFilters.FILTER_TAGS_SLUG]);
  const newTags = tags.filter(tag => tag !== 'superhost');

  if (newTags.length === 0) {
    delete filters[ESearchFilters.FILTER_TAGS_SLUG];
  } else {
    filters[ESearchFilters.FILTER_TAGS_SLUG] = newTags.join(',');
  }

  return filters;
};

export const setFormFilter: TSetFormFilterFunction =
  (payload: ISearchFormFiltersPayload, ignoreSearchMeta) => (dispatch, getState) => {
    const before = getState().searchForm.filters;
    const ignoredFilters: (keyof ISearchFormFilters)[] = payload.ignoredFiltersUpdate || [];

    // Filters to be saved to form storage.
    // Iterate over the keys in the payload.filters object, preserving only the filters
    // that are not in the ignoredFilters array.
    const { [ESearchFilters.DELIVERY_CAMPGROUND]: _, ...newFilters } = payload.filters;

    const formFilters = Object.keys(payload.filters).reduce<Partial<ISearchFormFilters>>(
      (acc, key) => {
        const k = key as keyof ISearchFormFilters;
        const filterValue = payload.filters[k];
        // Add filter to accumulator if it isn't ignored
        if (!ignoredFilters.includes(k)) {
          acc[key as keyof Partial<ISearchFormFilters>] = filterValue as any;
        }
        return acc;
      },
      {},
    );

    dispatch<ISetFormFilterAction>({
      type: SET_FORM_FILTER,
      payload: { ...payload, filters: formFilters },
    });
    const after = getState().searchForm.filters;

    if (!isEqual(before, after)) {
      trackEvent({
        event: 'search/filter',
        action: 'apply',
        before,
        after,
      });

      // If there's no filter method, then this filter was not set as the result of user inpout
      // so we do not fire this event.
      if (payload.filterMethod != null) {
        const filters = Object.keys(payload.filters);

        let beforeEventFilters = pick(before, filters) as TEventFilters;
        let afterEventFilters = pick(after, filters) as TEventFilters;
        let filterType = Object.keys(payload.filters);

        const superhostFilterChanged = isSuperhostFilterChanged(
          beforeEventFilters,
          afterEventFilters,
        );

        switch (superhostFilterChanged) {
          case 'before':
            afterEventFilters.superhost = 'false';
            beforeEventFilters.superhost = 'true';
            break;
          case 'after':
            afterEventFilters.superhost = 'true';
            beforeEventFilters.superhost = 'false';
            break;
          case 'no-change':
            break;
        }

        if (superhostFilterChanged !== 'no-change') {
          afterEventFilters = removeSuperhostFromFilterTags(afterEventFilters);
          beforeEventFilters = removeSuperhostFromFilterTags(beforeEventFilters);

          filterType = [
            ...filterType.filter(type => {
              // Keep FILTER_TAGS_SLUG if it exists in either before or after filters
              if (type === ESearchFilters.FILTER_TAGS_SLUG) {
                return (
                  ESearchFilters.FILTER_TAGS_SLUG in beforeEventFilters ||
                  ESearchFilters.FILTER_TAGS_SLUG in afterEventFilters
                );
              }
              return true;
            }),
            'superhost',
          ];
        }

        trackRenterSearchFilterUpdatedEvent({
          rentalType: ERentalType.RV,
          newFilters: afterEventFilters,
          previousFilters: beforeEventFilters,
          filterMethod: payload.filterMethod,
          filterType,
          isSEO: false,
        });
      }
    }

    return ignoreSearchMeta
      ? null
      : dispatch(getSearchMetaResults(newFilters, payload.ignorePersistedFilters));
  };

export const clearFormFilter: TClearFormFilterFunction = payload => (dispatch, getState) => {
  const {
    filters: filtersFromData,
    filterFeatures: filterFeaturesFromData,
    filtersToRestore = [],
  } = payload;
  const { searchForm: filtersFromState, queryParams: filtersFromQuery } = getState();

  const newFilters: NodeJS.Dict<string | string[]> = {};

  // Restore filters from form storage or query parameters.
  // If a filter to be restored does not exist in storage, fall back to the query parameter.
  for (const filter of filtersToRestore) {
    const key = filter as keyof ISearchFormFilters;
    newFilters[filter] = (filtersFromState.filters[key] || filtersFromQuery[key]) as string;
  }

  filtersFromData.forEach(filter => {
    if (
      filter === ESearchFilters.FILTER_FEATURE ||
      filter === ESearchFilters.CAMPSITE_RV_SITE_FEATURES ||
      filter === ESearchFilters.CAMPSITE_TENT_SITE_FEATURES ||
      filter === ESearchFilters.CAMPSITE_LODGING_FEATURES
    ) {
      const featuresList = filterFeaturesFromData?.[filter];
      newFilters[filter] = featuresList?.length ? featuresList : undefined;
    } else {
      newFilters[filter] = undefined;
    }
  });

  if (!Object.keys(filtersFromState).length || isEqual(filtersFromState, newFilters)) {
    return null;
  }

  return dispatch(
    setFormFilter({ filters: newFilters, filterMethod: null, ignorePersistedFilters: false }),
  );
};

export type TState = ISearchFormFiltersPayload;
export const initialState: TState = { filterMethod: null, filters: {} };

export default function reducer(state = initialState, action: TAction) {
  switch (action.type) {
    case SET_FORM_FILTER:
      return {
        filterMethod: action.payload.filterMethod,
        filters: { ...state.filters, ...action.payload.filters },
      };
    case RESET_FORM:
      return Object.keys(state.filters).reduce<ISearchFormFiltersPayload>(
        (acc, cur) => {
          const nextValue = PERSIST_FORM_FILTERS.includes(cur as ESearchFilters)
            ? state.filters[cur as keyof ISearchFormFilters]
            : undefined;
          return {
            filterMethod: null,
            filters: { ...acc.filters, [cur]: nextValue },
          };
        },
        { filters: {}, filterMethod: null },
      );
    default:
      return state;
  }
}
